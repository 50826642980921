import {useState, useEffect} from 'react'
import classes from './Game.module.css'
import wordlist from '../../assets/wordlist'
import { Stage, Layer, Group, Rect, Text, Image } from 'react-konva';
import useImage from 'use-image'
import pointingfinger from '../../assets/pointingfinger.png'
import {shuffle} from '../../helpers.js'
const makeSquares = () => {
  let squarelist = []
  for (let i=0; i < 3; i++){
    for (let j=0; j < 5; j++){
      if (j==1){
        continue
      }
      let newsquare = {
        id: i.toString()+j.toString(),
        letter: '',
        xPos: i*100,
        yPos: j*100,
        x: i,
        y: j,
        isActive: false
      }
      squarelist.push(newsquare)
    }
  }
  return squarelist
}


const Game = ({endGame, quitGame}) => {
  //for scaling. probably will be one of two values
  const [blocksize, setBlocksize] = useState(84)
  //list of squares the tiles will snap to. this includes the home row.
  const [squares, setSquares] = useState(makeSquares())
  //list of tiles
  const [tiles, setTiles] = useState([])
  //the empty square a tile is being dragged over, if any.
  const [targetSquareId, setTargetSquareId] = useState(null)
  const [sourceSquareId, setSourceSquareId] = useState(null)
  const [squareDestination, setSquareDestination] = useState(null)
  //for determining if a move can be commited - essentially if two tiles have
  //been placed.
  const [validBoard, setValidBoard] = useState(false)
  //determining what tiles are in full words
  const [validRows, setValidRows] = useState([])
  //word list, shuffled and loaded at the beginning of the game.
  const [wordList, setWordList] = useState(null)
  // pointer for the word list, determines current points, helps give new tiles
  //unique ids, etc
  const [wordNum, setWordNum] = useState(null)

  const [flag, setFlag] = useState(false)
  const [frozenHome, setFrozenHome] = useState(null)

  const [exitScreen, setExitScreen] = useState(false)

  //getting initial word list
  const [xmousePos, setXMousePos] = useState(0)
  const [ymousePos, setYMousePos] = useState(0)
  const [isClick, setIsClick] = useState(false)

  const [image] = useImage(pointingfinger)

  const handleMouseMove = (e) => {
    console.log(e)
    setXMousePos(e.evt.offsetX)
    setYMousePos(e.evt.offsetY)

  }
  const handleMouseDown = (e) => {
    setIsClick(true)
  }
  const handleMouseUp = (e) => {
    setIsClick(false)
  }

  const handleGameOver = () => {
  endGame(wordNum)
  }




  //make new tiles from word
  const addTiles = () => {
    if (wordNum < 0){
      return
    }
    let word = wordList[wordNum]
    let wordParts = word.split('')
    let newTiles = wordParts.map((w, i) => (
      {
        id: 'w'+wordNum.toString()+'l'+i.toString(),
        letter: w,

        x: i*blocksize,

        y: 0,

        isDragging:false,
        canMove:true,

        sitsOn:i.toString()+'0'
      }
    ))



    let newboard = [...tiles.filter(tile => tile.sitsOn[1] != '0' && (tile.canMove || !validRows.includes(tile.sitsOn))).map(tile => ({...tile, canMove:false})), ...newTiles]
    setTiles(newboard)

    if (newboard.length >= 11){
      handleGameOver()
    }


  }
  //for checking what square a dragged tile is over
  const getDraggedOver = (tileX, tileY) => {
    let baseX = Math.round(tileX/blocksize)
    let baseY = Math.round(tileY/blocksize)
    let foundSquare = squares.find(sq => sq.id === baseX.toString()+baseY.toString())
    let foundId = foundSquare ? foundSquare.id : null
    return foundId
  }
  const handleDragStart = (e) => {
    if (sourceSquareId){
      return
    }
    let chosenId = e.target.id()
    let chosentile = tiles.find(tile => tile.id === e.target.id())
    setSourceSquareId(chosentile.sitsOn)
    setTiles(tiles.map(tile => tile.id === chosenId ? {...tile, isDragging:true} : tile))
  }
  const handleDragMove = (e) => {
    setXMousePos(e.evt.offsetX)
    setYMousePos(e.evt.offsetY)
    let tilex = e.target.x()
    let tiley = e.target.y()
    let oversquare = getDraggedOver(tilex, tiley)
    setTargetSquareId(oversquare)
  }
  const handleDragEnd = (e) => {
    let squareDestId = (targetSquareId && !tiles.find(tile => tile.sitsOn === targetSquareId)) ? targetSquareId : sourceSquareId

    let targetX = Number(squareDestId[0])*blocksize
    let targetY = Number(squareDestId[1])*blocksize
    e.target.to({x: targetX, y:targetY, duration: 0.1, onFinish: ()=>updateTiles(squareDestId)})
  }

  const updateTiles = (squareDestId) => {

    setTiles(tiles.map(tile => tile.isDragging ? {...tile, sitsOn: squareDestId, isDragging:false} : tile))
    setSourceSquareId(null)
    setTargetSquareId(null)

  }
  const getQuit = () => setExitScreen(true)
  const cancelQuit = () => setExitScreen(false)

  const checkSquareArr = (arr) => {
    let rowword = ''
    for (let i=0; i < 3; i++){
      let squarecheck = arr[i]
      let tilecheck = tiles.find(tile => tile.sitsOn === squarecheck)
      if (!tilecheck){
        return false
      }
      rowword += tilecheck.letter
    }
    return wordList.includes(rowword)

  }

  const checkValidRows = () => {
    if (!squares || !wordList){
      return
    }
    let rowList = [['02','12','22'],['03','13','23'],['04','14','24'], ['02','03','04'],['12','13','14'],['22','23','24']]

    let rowWords = rowList.filter(row => checkSquareArr(row)).flat()
    setValidRows(rowWords)


  }
  const checkValidBoard = () => {
    let hometiles = tiles.filter(tile => tile.sitsOn[1]=='0')
    if (hometiles.length == 1){
      let frozenId = hometiles[0].id
      setFrozenHome(frozenId)
      setValidBoard(true)
    }
    else{
      setFrozenHome(null)
      setValidBoard(false)
    }

  }

  useEffect(() => {
    //find and set valid words
    checkValidRows()
    checkValidBoard()


  }, [tiles])

  useEffect(() => {
    setWordList(shuffle(wordlist))
  }, [])
  useEffect(() => {
    if (!wordList || wordNum != null){
      return
    }
    setWordNum(0)
  }, [wordList])

  useEffect(() => {
    if (wordNum == null){
      return
    }
    if (wordNum == wordList.length -1){
      setWordList(wl => [...wl, ...shuffle(wordlist)])
    }
    addTiles()
  }, [wordNum])

  const updateNum = () => setWordNum(x => x+1)










  return (
    <div className={classes.outerContainer} style={{cursor:'url(../../assets/pointingfinger.cur), auto'}}>
      {exitScreen && <div className={classes.quitLayer}>
      <div className={classes.blurScreen}></div>
      <div className={classes.quitOptions}>
        <div className={classes.quitMessage}>
          Are you sure?
        </div>
        <div>Your progress will not be saved.</div>
        <div className={classes.quitButtonHolder}>
          <button className={classes.quitButton} onClick={quitGame}>Leave Game</button>

          <button className={classes.quitButton} onClick={cancelQuit}>Cancel</button>

        </div>
      </div>
    </div>}
    {//<div className={classes.getQuitHolder}>
      //<button className={classes.getQuit} onClick={getQuit}>Leave Game</button>
    //</div>
  }


      <Stage offsetX={-blocksize/4} offsetY={-blocksize/4} width={3.5*blocksize} height={5.5*blocksize} onMouseMove={handleMouseMove} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>

        <Layer >
          {squares.map(square => (
            <Rect key={square.id}

              id={square.id}
              width={blocksize-6}
              height={blocksize-6}
              x={square.x*blocksize+3}
              y={square.y*blocksize+3}
              cornerRadius={12}


              fill={square.id === targetSquareId ? '#aaa' : '#e4e4e4'}
              ></Rect>
          ))}
        </Layer>
        <Layer >
          {tiles.map(tile => (
            <Group key={tile.id}
              id={tile.id}


              x={tile.x}
              y={tile.y}
              zIndex={tile.isDragging ? 30 : 2}



              draggable={tile.canMove && tile.id != frozenHome && (tile.isDragging || !sourceSquareId)}
              onDragStart={handleDragStart}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}>
              <Rect width={blocksize-6}

                height={blocksize-6}
                x={3}
                y={3}
                cornerRadius={12}


                strokeWidth={1}
                stroke={'#000'}
                shadowColor={tile.isDragging ? 'black' : 'transparent'}
                shadowBlur={5}
                shadowOffset={{x:5,y:5}}

                shadowOpacity={tile.isDragging ? .5 : 0}
                fill={tile.id == frozenHome ? "#e2e2e2" : tile.canMove ? "tan" : validRows.includes(tile.sitsOn) ? "#88bb88" : "#e2e2e2"}>
              </Rect>
              <Text text={tile.letter}
                width={blocksize-6}
                height={blocksize-6}


                x={4}
                y={4}
                fontSize={40}
                fill={"#000"}
                verticalAlign='middle'

                align="center">

              </Text>
            </Group>

          ))}
        </Layer>



      </Stage>
      <button className={classes.moveButton} disabled={!validBoard || !!sourceSquareId} onClick={updateNum}>commit move</button>
      <p style={{marginTop:5,fontSize:22}}>Points: {wordNum}</p>
    </div>
  )
}
export default Game
