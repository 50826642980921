import {useState, useEffect} from 'react'
import classes from './RulesOverview.module.css'

const RulesOverview = ({togglePage}) => {
  const toMenu = () => {
    togglePage("menu")
  }

  return (
    <div className={classes.outerContainer}>
      <div className={classes.backHolder}>
        <button className={classes.menuButton} onClick={toMenu}>Menu</button>
      </div>
    <h2>Rules</h2>
    <p>
  Trio Jam is a game of letter survival. Place tiles, spell three letter words, clear your board, and see how long you last!
</p>
<p>
  Each turn, you’ll get three fresh tiles, and must place two on the board.
</p>
<p>
Spelling three letter words clears tiles from the board. When you’ve spelled at least one three letter word horizontally and/or vertically, any previously played tiles in the word will disappear (they’ll turn green first, before you commit to your turn).</p>
<p>When there’s not enough room to place two tiles, it’s game over. Happy spelling!</p>

    </div>
  )
}
export default RulesOverview
