import {useState, useEffect} from 'react'
import {useCookies} from 'react-cookie'
import classes from './Homepage.module.css'

import Menu from '../Menu/Menu'
import Game from '../Game/Game'
import GameOver from '../GameOver/GameOver'
import Rules from '../Rules/RulesOverview'

import {useAuthContext} from '../../hooks/useAuthContext'
import {useFirestore} from '../../hooks/useFirestore'
const Homepage = () => {

  const [shownPage, setShownPage] = useState("menu")

  const [gameScore, setGameScore] = useState(0)
  const [bestScore, setBestScore] = useState(0)

  const [cookies, setCookie] = useCookies(['topscore'])
  const {updateDocument, addDocument} = useFirestore("users")
  const {user} = useAuthContext()


  const togglePage = (newpage) => setShownPage(newpage)

  useEffect(() => {
    if (cookies.topscore){
      setBestScore(cookies.topscore)
      return
    }
    setBestScore(0)
    setCookie('topscore', 0, {path: '/'})
  }, [])

  const endGame = (score) => {
    setGameScore(score)
    let newbest = Math.max(bestScore, score)

    setBestScore(newbest)
    setCookie('topscore', newbest, {path:'/'})
    setShownPage("gameover")

  }

  const quitGame = () => {
    setGameScore(0)
    setShownPage("menu")
  }


  return (
    <div className={classes.outerContainer}>
      {shownPage=="menu" && <Menu togglePage={togglePage} />}
      {shownPage=="game" && <Game endGame={endGame} quitGame={quitGame}/>}

      {shownPage=="rules" && <Rules togglePage={togglePage} />}
      {shownPage=="gameover" && <GameOver gameScore={gameScore} bestScore={bestScore} togglePage={togglePage}/>}



    </div>
  )
}
export default Homepage
