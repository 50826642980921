import {useState, useEffect} from 'react'
import classes from './GameOver.module.css'
import {analytics} from '../../firebase/config'
const GameOver = ({gameScore, bestScore, togglePage}) => {

  const playAgain = () => {
    analytics.logEvent('game_replayed')
    analytics.logEvent('game_played')

    togglePage("game")
  }
  const toMenu = () => togglePage("menu")

  return (
    <div className={classes.outerContainer}>
      <div className={classes.summaryCard}>
        <h1>Game Over</h1>
        <h2>Score: {gameScore}</h2>
        <h2>Personal Best: {bestScore}</h2>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
          <button onClick={playAgain} className={classes.gameoveroption}>Play Again</button>
          <button onClick={toMenu} className={classes.gameoveroption}>Main Menu</button>
        </div>
      </div>
    </div>
  )
}
export default GameOver
