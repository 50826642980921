import {projectFirestore, timestamp} from './firebase/config'
import wordlist from './assets/wordlist'
//fisher-yates shuffle
export function shuffle(array) {
  var m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}


export const testdocadd = () => {
  for (let i=0; i < 15; i++){
    let randlist = shuffle(wordlist)
    let newdate = new Date(2022, 4, 28+i)
    let ds = newdate.toDateString()

    var docdata = {
      stamp: timestamp.fromDate(newdate),
      wordmix: randlist

    }
    projectFirestore.collection('dailyjams').doc(ds).set(docdata)

  }
}
export const getDayRef = () => {
  let today = new Date()
  const ds = today.toDateString()
  const docPromise = projectFirestore.collection('dailyjams').doc(ds).get()
  console.log(docPromise)
  return docPromise
}
