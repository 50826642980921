import logo from './logo.svg';
import './App.css';
import Homepage from './pages/Homepage/Homepage'
import {getDayRef} from './helpers'
// import {BrowserRouter, Routes, Route} from 'react-router-dom'
// import {useAuthContext} from './hooks/useAuthContext'
function App() {
  const testgetDaily = () => {
    getDayRef().then(doc =>{
      if (doc.exists){
        const daymix = doc.data().wordmix
        console.log(daymix)

      } else {
        console.log('no such document')
      }
    }).catch((err) => {
      console.log('error getting document', err)
    })

  }

  return (
    <div className="App">

      <Homepage />



    </div>
  );
}

export default App;
