const wordlist = [
"ABY",
"ACE",
"ACT",
"ADD",
"ADO",
"AFT",
"AGA",
"AGE",
"AGO",
"AHA",
"AID",
"AIL",
"AIM",
"AIR",
"ALA",
"ALB",
"ALE",
"ALL",
"ALP",
"ALT",
"AMP",
"ANA",
"AND",
"ANT",
"ANY",
"APE",
"APT",
"ARC",
"ARE",
"ARK",
"ARM",
"ART",
"ASH",
"ASK",
"ASP",
"ASS",
"ATE",
"AUK",
"AVE",
"AWE",
"AWN",
"AXE",
"AYE",
"BAA",
"BAD",
"BAG",
"BAH",
"BAN",
"BAP",
"BAR",
"BAT",
"BAY",
"BED",
"BEE",
"BEG",
"BEL",
"BEN",
"BET",
"BIB",
"BID",
"BIG",
"BIN",
"BIT",
"BIZ",
"BOA",
"BOB",
"BOG",
"BOO",
"BOP",
"BOT",
"BOW",
"BOX",
"BOY",
"BRA",
"BUB",
"BUD",
"BUG",
"BUM",
"BUN",
"BUR",
"BUS",
"BUT",
"BUY",
"BYE",
"CAB",
"CAD",
"CAM",
"CAN",
"CAP",
"CAR",
"CAT",
"CAW",
"COB",
"COD",
"COG",
"COL",
"CON",
"COO",
"COP",
"COR",
"COT",
"COW",
"COX",
"COY",
"CRY",
"CUB",
"CUD",
"CUE",
"CUP",
"CUR",
"CUT",
"DAB",
"DAD",
"DAM",
"DAN",
"DAY",
"DEB",
"DEE",
"DEN",
"DEW",
"DID",
"DIE",
"DIG",
"DIM",
"DIN",
"DIP",
"DOC",
"DOE",
"DOG",

"DON",
"DOR",
"DOT",
"DRY",
"DUB",
"DUD",
"DUE",
"DUG",
"DUN",
"DUO",

"DYE",
"EAR",
"EAT",
"EBB",
"EEL",
"EGG",
"EGO",
"EKE",
"ELF",
"ELK",
"ELL",
"ELM",
"ELS",
"EMS",
"EMU",
"END",
"EON",
"ERA",
"ERE",
"ERG",
"ERR",

"ETA",
"EVE",
"EWE",

"EYE",
"FAB",
"FAD",
"FAN",
"FAR",
"FAT",
"FAX",
"FED",
"FEE",
"FEN",
"FEW",
"FEZ",
"FIB",
"FIG",
"FIN",
"FIR",
"FIT",
"FIX",
"FLY",
"FOB",
"FOE",
"FOG",
"FOP",
"FOR",
"FOX",
"FRO",
"FRY",
"FUG",
"FUN",
"FUR",
"GAB",
"GAD",
"GAG",
"GAL",
"GAP",
"GAS",
"GAY",
"GEL",
"GEM",
"GEN",
"GET",
"GIG",
"GIN",
"GNU",
"GOB",
"GOD",
"GOO",
"GOT",
"GUM",
"GUN",
"GUT",
"GUY",
"GYM",
"HAD",
"HAG",
"HAM",
"HAS",
"HAT",
"HAY",
"HEM",
"HEN",
"HER",
"HEW",
"HEX",
"HEY",
"HIC",
"HID",
"HIM",
"HIP",
"HIS",
"HIT",
"HMM",
"HOB",
"HOD",
"HOE",
"HOG",
"HOP",
"HOT",
"HOW",
"HUB",
"HUE",
"HUG",
"HUH",
"HUM",
"HUT",
"ICE",
"ICY",
"IDS",
"IFS",
"ILK",
"ILL",
"IMP",
"INK",
"INN",
"INS",
"ION",
"IRE",
"IRK",
"ITS",
"IVY",
"JAB",
"JAG",
"JAM",
"JAR",
"JAW",
"JAY",
"JET",
"JEW",
"JIG",
"JOB",
"JOG",
"JOT",
"JOY",
"JUG",
"JUT",
"KEG",
"KEN",
"KEY",
"KID",
"KIN",
"KIT",
"LAB",
"LAD",
"LAG",
"LAM",
"LAP",
"LAS",
"LAW",
"LAX",
"LAY",
"LEA",
"LED",
"LEE",
"LEG",
"LET",
"LID",
"LIE",
"LIP",
"LIT",
"LOB",
"LOG",
"LOO",
"LOP",
"LOT",
"LOW",
"LUG",

"LUX",
"LYE",
"MAC",
"MAD",
"MAG",
"MAN",
"MAP",
"MAR",
"MAS",
"MAT",
"MAW",
"MAY",
"MEN",
"MET",
"MEW",
"MID",
"MIX",
"MOB",
"MOD",
"MOM",
"MOO",
"MOP",
"MOW",
"MUD",
"MUG",
"MUM",
"NAB",
"NAG",
"NAP",
"NAY",
"NEE",
"NET",
"NEW",
"NIB",
"NIL",
"NIP",
"NIT",
"NOD",

"NOR",
"NOT",
"NOW",
"NUN",
"NUT",
"OAF",
"OAK",
"OAR",
"OAT",
"ODD",
"ODE",
"OFF",
"OFT",
"OHM",
"OIL",
"OLD",
"OLE",

"ONE",
"OOH",
"OPT",
"ORB",
"ORE",
"OUR",
"OUT",
"OVA",
"OWE",
"OWL",
"OWN",
"PAD",
"PAL",
"PAN",
"PAP",
"PAR",
"PAT",
"PAW",
"PAY",
"PEA",
"PEE",
"PEG",
"PEN",
"PEP",
"PER",
"PET",
"PEW",
"PIE",
"PIG",
"PIN",
"PIP",
"PIT",
"PLY",
"POD",
"POP",
"POT",
"POX",

"PRO",
"PRY",
"PUB",
"PUG",
"PUN",
"PUP",
"PUS",
"PUT",
"QUA",
"RAD",
"RAG",
"RAJ",
"RAM",
"RAN",
"RAP",
"RAT",
"RAW",
"RAY",
"RED",
"REF",
"REM",
"REP",
"REV",
"RHO",
"RIA",
"RIB",
"RID",
"RIG",
"RIM",
"RIP",
"ROB",
"ROC",
"ROD",
"ROE",
"ROM",

"ROT",
"ROW",
"RUB",
"RUE",
"RUG",
"RUM",
"RUN",
"RUT",
"RYE",
"SAC",
"SAD",
"SAG",

"SAP",
"SAT",
"SAW",
"SAY",
"SEA",
"SEC",
"SEE",
"SEN",
"SET",
"SEW",
"SEX",
"SHE",
"SHY",
"SIC",
"SIN",
"SIP",
"SIR",
"SIS",
"SIT",
"SIX",
"SKI",
"SKY",
"SLY",
"SOB",
"SOD",

"SON",
"SOP",
"SOS",
"SOT",
"SOW",
"SOY",
"SPA",
"SPY",
"STY",
"SUB",

"SUE",
"SUM",
"SUN",
"SUP",
"TAB",
"TAD",
"TAG",
"TAN",
"TAP",
"TAR",
"TAT",
"TAU",
"TAX",
"TEA",
"TED",
"TEE",
"TEN",
"THE",
"THY",
"TIC",
"TIE",
"TIN",
"TIP",
"TIT",
"TOE",
"TOG",
"TON",
"TOO",
"TOP",
"TOT",
"TOW",
"TOY",
"TRY",
"TUB",
"TUG",
"TUN",
"TWO",

"UKE",
"UPS",
"URN",
"USE",
"VAN",
"VAT",
"VEG",
"VET",
"VEX",
"VIA",
"VIE",
"VIM",
"VOW",
"WAD",
"WAG",
"WAN",
"WAR",
"WAS",
"WAX",
"WAY",
"WEB",
"WED",
"WEE",
"WET",
"WHO",
"WHY",
"WIG",
"WIN",
"WIT",
"WOE",
"WOK",
"WON",
"WOO",
"WOW",
"WRY",
"YAK",
"YAM",
"YAP",
"YAW",
"YEA",
"YEN",
"YEP",
"YES",
"YET",
"YEW",
"YIP",
"YON",
"YOU",
"ZAG",
"ZAP",
"ZIG",
"ZIP",
"ZOO",
"TAO",
"GIT",
"POW",
"LOX",
"TUT",
"NUB",
"WIZ",
"ZIT",
"VIG",
"NIX",
"APP",
"BAM",
"YOW",
"NOG",
"GIB",
"GEE",
"VEE",

"AAH",
"AAL",

"ABS",
"ADS",
"ADZ",

"AHI",
"AHS",

"ARF",

"AWL",

"BIO",

"BOD",

"BRO",


"CEE",
"CEL",

"CHI",

"CIS",


"DAL",

"DEF",

"DIB",

"DIS",

"DUH",

"EEK",
"EFF",

"ENS",

"ESS",

"FAS",

"FEH",

"FEY",

"FLU",

"HAH",

"HAW",

"ICK",

"JIB",
"JIN",
"JOE",

"KAY",

"KIP",
"KIR",


"KOI",

"LEI",



"MAX",
"MED",

"MIC",
"MIG",

"NOS",

"OBI",

"ONS",

"OPS",

"ORC",
"ORS",
"ORT",
"OUT",

"PAC",

"PAS",
"PAX",

"PHI",

"PIC",
"PIS",

"POO",
"PSI",

"QAT",
"QIS",
"RAH",

"REC",

"SIB",

"SKA",
"SOL",

"TIL",
"TIS",
"TOM",

"TUX",
"UMP",

"VID",

"VOX",

"YAY",

"YIN",

"YUK",
"YUM",
"ZED",
"ZEE",
]
export default wordlist
