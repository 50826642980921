import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/auth'
import 'firebase/firestore'
const firebaseConfig = {
  apiKey: "AIzaSyD9y_kcY5YLHs-5YZi01htRXdk3npywlrY",
  authDomain: "trio-jam.firebaseapp.com",
  projectId: "trio-jam",
  storageBucket: "trio-jam.appspot.com",
  messagingSenderId: "69866138009",
  appId: "1:69866138009:web:3f0c8a3386ec88d39c0b08",
  measurementId: "G-4W1KH17C4L"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()

const timestamp = firebase.firestore.Timestamp
// Initialize Analytics and get a reference to the service
const analytics = firebase.analytics();
export {analytics, projectAuth, projectFirestore, timestamp}
