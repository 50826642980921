import {useState, useEffect} from 'react'
import {analytics} from '../../firebase/config'
import classes from './Menu.module.css'
const Menu = ({togglePage}) => {
  const handleClick = (e) => togglePage(e.target.value)

  const handleGameStart = () => {
    analytics.logEvent('game_played')
    togglePage("game")

  }

  return (
    <div className={classes.outerContainer}>
      <div style={{display:'flex',flexDirection:'column'}}>
        <div className={classes.gameTitle}>TRIO JAM</div>
        <div className={classes.gameSubtitle}>a nice Little Word game</div>
      </div>
      <div className={classes.buttonHolder}>
        <button className={classes.buttonStyle} value="fourgame" onClick={handleGameStart}>PLAY</button>
        <button className={classes.buttonStyle} value="rules" onClick={handleClick}>RULES</button>



      </div>
      <div>

      <p style={{margin:4,padding:0,fontSize:22,fontWeight:'bold'}}>The Trio Jam app is here!</p><p style={{margin:4,padding:0,fontSize:22}}><a href="https://apps.apple.com/us/app/trio-jam/id1623691094">Get it now</a> on the app store (it's free)</p>

      <p style={{marginTop:30,padding:0,fontSize:22}}><i>Coming soon to Google Play</i> </p>
      </div>



    </div>
  )
}
export default Menu
